.main-container {
  position: relative;
  z-index: 2;
  max-width: 200px;
}

.table-con {
  margin-top: 40px;
}

.main-table {
  position: relative;
  margin-left: 50px;
  margin-top: 50px;
  height: 100px;
  width: 200px;
  display: block;
  z-index: 1000 !important;
}

.table-order {
  font-size: 30px;
  text-align: right;
  width: 100%;
  margin-left: -15px;
  padding-top: 5px;
  color: #fff;
  display: block;
  position: relative;
  z-index: 5 !important;
}

.order-in-quee {
  font-size: 12px;
  text-align: right;
  width: auto;
  right: -10px;
  top: -10px;
  display: block;
  color: #fff;
  display: block;
  position: absolute;
  border-radius: 50%;
}

.left-line {
  border-radius: 10px 0 0 10px;
}

.table-order-table {
  background-color: rgba(66, 84, 79, 0.7);
  width: 200px;
  margin-top: -50px;
  height: 100px;
  color: #fff;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 4 !important;
}


.busy .chair {
  background: rgb(245, 5, 5);
}

.pending .chair {
  background: #FF9F43;
}

.left-line {
  border-left: 5px solid transparent;

}

.pending .left-line {
  border-left-color: #FF9F43;
}

.busy .left-line {
  border-left-color: rgb(245, 5, 5);
}

.free .left-line {
  border-left-color: rgb(27, 155, 38);
}

.free .chair {
  background-color: rgb(27, 155, 38);
}

.compact .main-container {
  width: 50px;
}

.compact .main-table,
.compact .table-order-table {
  width: 50px;
  height: 50px;
}

.compact .chair {
  width: 15px;
  height: 15px;
}

.compact .chair1 {
  top: -10px;
  left: 17px;
}

.single-table-customer {
  background: black;
}

.back-button {
  background-color: rgb(205, 199, 25);
  font-size: 13px;
  padding: 10px 20px;
  text-transform: capitalize;
  margin-top: 0;
}

.notication {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 10px;
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s infinite ease-in-out;
  cursor: pointer;
}