.qty-item {
    display: flex;
    gap: 5px;
}

.qty-item input {
    max-width: 35px;
    height: 15px;
    font-size: 12px;
    padding: 0;
}