.chair {
  border-radius: 50%;
  width: 50px;
  position: absolute;
  height: 50px;
  z-index: 1;
}

.chair1 {
  top: -20px;
  left: 20px;
}

.chair2 {
  top: -20px;
  right: 20px;
}

.chair3 {
  bottom: -20px;
  left: 20px;
}

.chair4 {
  bottom: -20px;
  right: 20px;
}

.chair5 {
  bottom: 22px;
  right: -28px;
}

.chair6 {
  bottom: 22px;
  left: -28px;
}
