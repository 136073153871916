.big-menu {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 20px;
  gap: 20px;
}

.product-recipt {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.product-list {
  width: 75%;
  display: block;
}

.single-product {
  display: flex;
  gap: 5px;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.single-product.disabled {
  opacity: 0.25 !important;
  cursor: not-allowed;
}

.product-qty {
  display: flex;
  justify-content: space-between;
}


.single-product p {
  margin: 0 !important;
}

.single-product .product-price {
  font-size: 12px;
}

.single-product .product-price span {
  font-weight: 500;
}

.product-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 10px;
  gap: 10px;
  max-height: 68dvh;
  overflow-y: scroll;
}

.products {
  padding: 20px;
  border-radius: 5px;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 90%;
  border: 1px solid grey;
  overflow-x: scroll;
}

.category-button {
  width: 100%;
  border-radius: 5px;
  height: 50px;
  background: linear-gradient(145deg, #e6e4da, #ffffff);
  box-shadow:
    6px 6px 10px #cfcdc4,
    -6px -6px 10px #ffffff;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.recipts-and-order {
  width: 25%;
}

.active-order {
  margin-bottom: 10px;
  padding: 0;
}

.accordion-header button {
  margin-top: 0;
}

.recipts {
  border: 1px dotted #FF9F43;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  min-height: 187px;
}

.recipts h3 {
  text-decoration: underline wavy #FF9F43;
  width: 100;
  text-align: center;
  padding: 7px 10px;
  margin-bottom: 11px;
}

.recipt-header button {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  padding: 7px 14px;
  margin-bottom: 11px;
}

.recipt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipt-item p {
  margin: 0;
}

.recipt-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  justify-items: flex-start;
  margin: 3px 0;
  box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
}

.recipts .product-price {
  font-size: 12px;
  font-weight: 500;
}

.total-recipt {
  text-align: right;
  padding: 10px
}

.total-recipt span {
  font-weight: 600;
}

.delete-icon {
  display: flex;
  justify-content: center;
  width: 100%;
}

.order-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-footer .total {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.order-footer button {
  font-size: 12px;
  padding: 10px;
  text-transform: capitalize;
}

.pending button {
  background: #e2d2c4;
  color: black;
}

.accordion-item button:focus {
  box-shadow: unset !important;
}

.in_progress button {
  color: black;
  background-color: rgb(196 255 196);
}

.recipt-order-product {
  display: flex;
  justify-content: space-between;
}

.recipt-order-product p {
  font-size: 12px;
}

.recipt-order-product p:last-child {
  font-weight: 500;
}

.recipt-order-product+hr {
  margin: 5px 0;
}

.accordion-button {
  font-size: 12px;
  text-transform: capitalize;
  padding: 10px;
}

.order-time {
  font-weight: 600;
  font-size: 11px;
  text-decoration: underline;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}

.action-order-button {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  gap: 10px;
  margin-top: 5px;
}

.action-order-button button {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  padding: 10px;
}

.danger-button {
  background: red !important;
  color: white !important;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 50%;
  border-radius: 8px;
  text-align: center;
}

.modal button {
  margin: 10px;
  padding: 10px;
  border: none;

}

.modal button:first-of-type {

  background-color: #f44336;
  color: white;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
}

.user-action-buttons {
  display: flex;
  justify-content: space-evenly;
}

.user-action-buttons button {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px;
  background-color: darkcyan;
}


@media (max-width: 992px) {
  .product-recipt {
    width: 100%;
  }

  .recipts-and-order {
    width: 100%;
  }

  .product-list {
    width: 100%;
  }

  .product-container {
    grid-template-columns: repeat(3, 1fr);
  }
}