.arrow-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.custom-arrow {
    background: black;
    margin: 0;
    padding: 0;
}

.custom-arrow:hover {
    background-color: rgb(78, 64, 64);
}

.category-menu .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border-radius: 5px;
    border: 2px solid transparent;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px 0;
}

.category-menu .slick-slide:hover {
    border: 1px solid #FF9F43;
}

.category-menu .slick-slide:hover svg {
    transition: transform 0.3s ease;
    transform: scale(1.2);
}

.category-menu .slick-current {
    background-color: #FF9F43;
    color: #fff !important;
}

.slick-track {
    display: flex;
    gap: 10px;
}