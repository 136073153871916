.hamburger {
  font-size: 30px;
  cursor: pointer;
  padding: 10px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.menu {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 50%;
  height: 50%;
}

.menu a {
  display: block;
  margin: 10px 0;
  color: black;
  text-decoration: none;
  font-size: 18px;
}

.menu a:hover {
  color: #007bff;
}
