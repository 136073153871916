.tab-button {
    width: auto;
    position: relative;
}

.notification-badge {
    display: inline-block;
    background-color: red;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 1.5rem;
    position: absolute;
    top: -5px;
    right: -10px;
}

.tab-container {
    display: flex;
    gap: 10px;
}

@media (max-width: 992px) {

    .tab-container {
        gap: 5px;
    }

    .tab-button {
        width: 50%;
        margin: 10px 0 !important;
    }
}

.notification-badge {
    display: inline-block;
    background-color: red;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 1.5rem;
    position: absolute;
    top: -5px;
    right: -10px;
}